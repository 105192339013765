import React from "react";
import styled from "styled-components";
import qubeIcon from "../../images/logo.png"


const GridTitle = styled.h3`
  text-align: left;
  font-size: 2.2rem;
  font-weight: 500;

  @media (max-width: 1480px) {
    font-size: 2rem;
  }

  @media (max-width: 920px) {
    font-size: 1.8rem;
  }

  @media (max-width: 720px) {
    font-size: 1.5rem;
  }
`;

const GridP = styled.p`
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;

  @media (max-width: 1480px) {
    font-size: 1.3rem;
  }

  @media (max-width: 920px) {
    font-size: 1rem;
  }
`;

function QubeTokenSection() {
    return (
        <>
            <section className="qubeToken-Section text-light px-2 py-5 text-center w-100" id="$QUBE Token">
                <div className="container-qubeToken-Section h-100 w-100">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="qubeToken-title-container">
                            <h3 class="qubeToken-subtitle">
                                Quintessential Utility Built Environment Token
                            </h3>
                            <h2 class="qubeToken-title">
                                $QUBE TOKEN
                            </h2>
                        </div>
                        <div className="qubeToken-mid-small align-items-start justify-content-start w-100 mb-5">
                            <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                <img className="" src={qubeIcon} alt="$QUBE" style={{ width: "200px", height: "200px" }} />
                            </div>
                            <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                <a href="https://www.qube.homeqube.com/" target="_blank" rel="noopener noreferrer" className="w-100">
                                    <button className="qubeSection-btn btn-lg rounded-pill px-5">
                                        Learn More
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="qubeToken-grid w-100">
                            <div className="qubeToken-side d-flex flex-column gap-4 align-items-start justify-content-between">
                                <div className="d-flex flex-column px-5 align-items-start justify-content-start">
                                    <GridTitle>
                                        Quintessential Utility Built Environment Token
                                    </GridTitle>
                                    <GridP>
                                        Users need $QUBE Tokens to join parametric games and set specific criteria,
                                        allowing them to find the best match for their needs
                                    </GridP>
                                </div>
                                <div className="d-flex flex-column px-5 align-items-start justify-content-start">
                                    <GridTitle>
                                        Facilitating Communication and Negotiation
                                    </GridTitle>
                                    <GridP>
                                        Holding $QUBE unlocks features like direct messaging, real-time bidding updates,
                                        and negotiation tools, making it easier for users to connect, negotiate, and secure favorable deals.
                                    </GridP>
                                </div>
                            </div>
                            <div className="qubeToken-mid align-items-start justify-content-start">
                                <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                    <img className="" src={qubeIcon} alt="$QUBE" style={{ width: "400px", height: "400px" }} />
                                </div>
                                <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                    <a href="https://www.qube.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                        <button className="traps-btn btn-lg rounded-pill px-5">
                                            Learn More
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="qubeToken-side d-flex flex-column gap-4 align-items-start justify-content-between">
                                <div className="d-flex flex-column px-5 align-items-start justify-content-start">
                                    <GridTitle>
                                        Boosting Community Engagement
                                    </GridTitle>
                                    <GridP>
                                        The $QUBE Token is also used to incentivize active community participation whether
                                        by providing feedback, participating in events, or sharing knowledge, fostering a
                                        collaborative environment.
                                    </GridP>
                                </div>
                                <div className="d-flex flex-column px-5 align-items-start justify-content-start">
                                    <GridTitle>
                                        Ensuring Security and Transparency
                                    </GridTitle>
                                    <GridP>
                                        Every transaction with $QUBE is recorded on a blockchain, ensuring transparency and reducing the
                                        risk of fraud, building trust in the ecosystem.
                                    </GridP>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default QubeTokenSection;
