import React, { useEffect, useState } from "react";

const ScrollTimeline = ({ sectionIds }) => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = null;
      sectionIds.forEach((id) => {
        const element = document.getElementById(id);
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          currentSection = id;
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sectionIds]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      const offset = 5 * 16; // 5rem converted to pixels (assuming 1rem = 16px)
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: "smooth" });
    }
  };

  return (
    <div style={{ display: "flex", }}>
      {/* Scroll Timeline */}
      <div
        style={{
          position: "fixed",
          right: "1.2rem",
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          alignItems: "flex-start",
          zIndex: 2,
          opacity: "95%",
        }}
      >
        {/* Vertical Line */}
        <div
          style={{
            position: "absolute",
            width: "2px",
            height: `${sectionIds.length * 60}px`, // Adjust based on dot size and margin
            backgroundColor: "lightgray",
            left: "9px", // Align with dot positioning
            top: "0", // Start from the top
          }}
        />
        
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {sectionIds.map((id) => (
            <div
              key={id}
              style={{ 
                margin: "20px 0", 
                cursor: "pointer", 
                position: "relative", 
                display: "flex", 
                alignItems: "center" // Align circle and text
              }}
              onClick={() => scrollToSection(id)}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: activeSection === id ? "white" : "gray",
                  transition: "background-color 0.3s",
                  marginRight: "10px", // Space between the circle and text
                }}
              />
              {/* <span className="scrollText" style={{ fontSize: "20px", color: "white", fontWeight: activeSection === id ? "800" : "400",}}>{id}</span> */}
               {/* Section name */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollTimeline;
