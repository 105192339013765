import React from 'react';
import styled from 'styled-components';

const MainCard = styled.div`
    background: transparent;
    padding: 1.3rem;
    width: 430px;
    height: 500px;
    max-height: 500px;

    @media (max-width: 1280px) {
        font-size: 3rem;
        width: 370px;
    }

    @media (max-width: 1150px) {
        width: 340px;
    }

    @media (max-width: 1065px) {
        width: 320px;
    }

    @media (max-width: 720px) {
        font-size: 2.5rem;
        height: 400px;
        max-height: 400px;
    }

    @media (max-width: 675px) {
        width: 80%;
    }

    @media (max-width: 620px) {
        width: 100%;
    }
`;

const CardGrid = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CardTitleContainer = styled.div`
    flex-direction: column;
    background-color: #fff;
    opacity: 90%;
    padding: 1.4rem;
    height: 30%;
    max-height: 30%;
`;

const CardTitle = styled.h3`
    font-size: 2rem;
    font-weight: 500;
    color: #000;
    width: 100%;
    text-align: center;
    margin: 0;

    @media (max-width: 1280px) {
        font-size: 1.5rem;
    }

    @media (max-width: 720px) {
        font-size: 1.2rem;
    }
`;

const CardContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #86868a;
    opacity: 90%;
    padding: 1.4rem;
    height: 70%;
    max-height: 70%;
    margin: 0;
    
`;

const CardContent = styled.p`
    font-size: 1.3rem;
    font-weight: 400;
    color: #fff;
    width: 100%;
    text-align: center;

    @media (max-width: 1280px) {
        font-size: 1.1rem;
    }

    @media (max-width: 720px) {
        font-size: 1rem;
    }
`;

const CardButton = styled.a`
    @media (max-width: 580px) {
        width: 100%;
    }
`;

const DaoCard = (props) => {
    const { title, title2, content, link, btnname } = props;

    return (
        <MainCard>
            <CardGrid>
                <CardTitleContainer className="d-flex justify-content-center align-items-center w-100">
                    <CardTitle>{title}<br/>{title2}</CardTitle>
                </CardTitleContainer>
                <CardContentContainer>
                    <CardContent>
                        {content}
                    </CardContent>
                    <CardButton href={link} target="_blank" rel="noopener noreferrer">
                        <button className="shop-btn btn-lg rounded-pill">
                            {btnname}
                        </button>
                    </CardButton>
                </CardContentContainer>
            </CardGrid>
        </MainCard>
    );
};

export default DaoCard;
