import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover .overlay-text {
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;

const OverlayText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

const BuiltEnvironmentEngineItem = (props) => {
  const { title, image, description } = props;

  return (
    <CardContainer className="built-environment-engine-card">
      <div className="p-2 d-flex flex-column align-items-center justify-content-start gap-2">
        <ImageContainer className="our-service-card-image">
          {image && <img src={image} alt={title} />}
          <OverlayText className="overlay-text">
            <p className='bee-card-p'>{description}</p>
          </OverlayText>
        </ImageContainer>
        <div className="our-service-card-title-container mb-3 d-flex flex-column align-items-center justify-content-center">
          <h3 className="built-environment-engine-card-title">{title}</h3>
        </div>
      </div>
    </CardContainer>
  );
};

export default BuiltEnvironmentEngineItem;
