import React from "react";
import styled from 'styled-components'

import newgenInternet from '../../images/CorporateFacelift/icons/newgen-internet-icon.png'
import newgenMaterials from '../../images/CorporateFacelift/icons/newgen-materials-icon.png'
import newgenEverything from '../../images/CorporateFacelift/icons/newgen-everything-icon.png'

const HomequbeTitle = styled.h2`
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 140%;
  /* or 67px */

  text-transform: uppercase;
  text-align: center !important;

  color: #ffffff;

    @media (max-width: 920px) {
      font-size: 2rem;
    }
`;

const NewgenIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const NewgenIcon = styled.img`
  height: 120px;

  @media (max-width: 920px) {
    height: 100px;
  }
  
  @media (max-width: 720px) {
  height: 80px;
  }
`;

function NewGenSection() {
  return (
    <>
      <section className="newgen-section text-light p-5 text-center text-sm-start" >
        <div className="container-bg container h-100 d-flex flex-column align-content-center justify-content-center pb-5">
          <div className="d-flex flex-column align-content-center justify-content-between container-content">
            {/* <div className="comp-logo-display d-flex align-content-center justify-content-center">
              <img src={qubelogo} alt="QUBE" />
            </div> */}
            <div className="hero-banner-container">
              <div>
                <h1 className="newgen-quote mb-5">
                  “Sustainability involves activities such as reducing waste, using renewable energy sources, and finding ways to conserve
                  resources; on the other hand, regeneration goes further, creating systems that work with nature to restore damaged
                  ecosystems.” - Anonymous
                </h1>
                <HomequbeTitle>
                  Homeqube
                </HomequbeTitle>
                <h1 className="newgen-title pb-3">
                  your Real-world assets platform for:
                </h1>
                <div className="newgen-items-container pb-5 gap-5">
                  <div className="newgen-col">
                    <NewgenIconContainer>
                      <NewgenIcon src={newgenInternet} alt="New Gen Internet" />
                    </NewgenIconContainer>
                    <p className="newgen-item-title">
                      New gen internet
                    </p>
                    <p className="newgen-item-text">
                      Web3
                    </p>
                  </div>
                  <div className="newgen-col">
                    <NewgenIconContainer>
                      <NewgenIcon src={newgenMaterials} alt="New Gen Materials" />
                    </NewgenIconContainer>
                    <p className="newgen-item-title">
                      New gen materials
                    </p>
                    <p className="newgen-item-text">
                      Pre-engineered buildings, lightweight materials
                    </p>
                  </div>
                  <div className="newgen-col">
                  <NewgenIconContainer>
                      <NewgenIcon src={newgenEverything} alt="New Gen Everything" />
                    </NewgenIconContainer>
                    <p className="newgen-item-title">
                      New gen Everything!
                    </p>
                    <p className="newgen-item-text">
                      Committed to continuous improvement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewGenSection;
