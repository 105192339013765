import React from 'react'
import styled from 'styled-components';

import howtoorder from "../../images/CorporateFacelift/img/Peb-How-to-Order-image.png"


const PebGrid = styled.div`
    width: 100%;
    height: 100%;
    gap: 3rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const PebFlexSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  height: 100%;

  @media (max-width: 1480px) {
    padding: 3rem;
  }

  @media (max-width: 1170px) {
    width: 100%;
    padding: 2rem;
  }

  @media (max-width: 720px) {
    padding: 1rem;
  }
`;

const PebTitle = styled.h2`
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;

@media (max-width: 1280px) {
        font-size: 3rem;
}

@media (max-width: 720px) {
        font-size: 2.5rem;
}
`;

const PebSubTitle = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    /* identical to box height, or 70px */
    text-align: center;
    color: #ffffff;
`;

const PebPTitle = styled.p`
    font-weight: 500;
    font-size: 3rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: left;
    color: #b8b9bb;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width: 1280px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (max-width: 720px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const PebP = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: left;
    color: #afafb1;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 150%;

    @media (max-width: 1280px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (max-width: 720px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const PebA = styled.a`
    @media (max-width: 580px) {
        width: 100%;
    }
`;


const PebHowToOrder = styled.img`
    width: 500px;
    height: 500px;
    border-radius: 10px;

    @media (max-width: 720px) {
        width: 300px;
        height: 300px;
    }
`;

function PebSection() {
    return (
        <section className="peb-Section text-light px-2 py-5 text-center w-100 h-100" id="PEB">
            <div className="container-peb-Section h-100 w-100">
                <PebGrid>
                    <PebFlexSide>
                        <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                            <PebTitle>
                                HomeQube PEB
                            </PebTitle>
                            <PebSubTitle>
                                Building the Future with AI and System Engineering
                            </PebSubTitle>
                        </div>
                        <PebPTitle>
                            Our Analogue Products
                        </PebPTitle>
                        <PebP>
                            Made from innovative materials like Glass Fiber Reinforced Polymer (GFRP), these products
                            are relocatable, adaptable, and environmentally friendly, offering flexible and affordable
                            housing solutions.
                        </PebP>
                        <div className="d-flex flex-column w-100 align-items-center justify-content-center mt-5">
                            <PebA href="https://peb.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <button className="shop-btn btn-lg rounded-pill">
                                    Browse Our AI-PEB
                                </button>
                            </PebA>
                        </div>
                    </PebFlexSide>
                    <PebFlexSide>
                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                            <PebHowToOrder src={howtoorder} alt="PebHowToOrder" />
                        </div>
                    </PebFlexSide>
                </PebGrid>
            </div>
        </section>
    )
}

export default PebSection