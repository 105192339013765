import React from "react";
import styled from "styled-components";

import laptopIcon from "../../images/CorporateFacelift/icons/shop-laptop-icon.png"

const ShopFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 1480px) {
  }

  @media (max-width: 1170px) {
    flex-direction: column;
  }

  @media (max-width: 720px) {
  }
`;

const ShopFlexSideA = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (max-width: 1480px) {
  }

  @media (max-width: 1170px) {
    width: 100%;
  }

  @media (max-width: 720px) {
  }
`;

const ShopFlexSideB = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 5rem;
  gap: 2rem;
  
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1480px) {
    padding: 3.5rem;
  }

  @media (max-width: 1170px) {
    width: 100%;
    padding: 2.8rem;
  }

  @media (max-width: 720px) {
    padding: 2rem;
  }

  @media (max-width: 580px) {
    padding: 1.5rem;
  }
`;

const ShopTitle = styled.h2`
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 2px;
    color: #ffffff;
    margin-bottom: 1.5rem;

@media (max-width: 1280px) {
        font-size: 4rem;
}

@media (max-width: 720px) {
        font-size: 2.7rem;
        font-weight: 600;
}
`;

const ShopP = styled.p`
    width: 100%;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;

  @media (max-width: 1480px) {
    font-size: 1.3rem;
  }

  @media (max-width: 920px) {
    font-size: 1rem;
  }
`;

const ShopSubtitles = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  background-color: #b8b9bb;
  color: #000;
  padding: 1.5rem;

  @media (max-width: 1480px) {
    font-size: 1.3rem;
  }

  @media (max-width: 920px) {
    font-size: 1rem;
  }
`;

const ShopGrid = styled.div`
    width: 100%;
    height: 100%;
    gap: 3rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const ShopContent = styled.div`
    @media (max-width: 1280px) {
        flex-direction: column-reverse !important;
    }
`;

const ShopA = styled.a`
    @media (max-width: 580px) {
        width: 100%;
    }
`;

const ShopLaptop = styled.img`
    width: 520px;
    height: 400px;

    @media (max-width: 720px) {
        width: 420px;
        height: 300px;
    }

    @media (max-width: 580px) {
        width: 320px;
        height: 200px;
    }
`;

function ShopSection() {
    return (
        <>
            <section className="shop-Section text-light px-2 py-5 text-center w-100 h-100" id="Bidding Shop">
                <div className="container-shop-Section h-100 w-100">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <ShopFlexContainer>
                            <ShopFlexSideA>
                                <ShopTitle>
                                    HOMEQUBE <br /> Home Value Chain <br /> Bidding Shop
                                </ShopTitle>
                                <div className="d-flex flex-column w-100 align-items-center justify-content-center mb-5">
                                    <ShopA href="https://www.shop.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                        <button className="shop-btn btn-lg rounded-pill">
                                            Find Great Deals
                                        </button>
                                    </ShopA>
                                </div>
                                <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                    <ShopLaptop src={laptopIcon} alt="ShopLaptopIcon" />
                                </div>
                            </ShopFlexSideA>
                            <ShopFlexSideB>
                                <ShopContent className="d-flex flex-column align-items-start justify-content-between gap-3">
                                    <ShopP >
                                        The HomeQube Home Value Chain Bidding Shop features an innovative <b>Gamified Bidding</b> system
                                        that transforms traditional transactions into dynamic, interactive experiences. Users can
                                        set specific parameters such as date, location, specifications, and quantity to create parametric
                                        games that match their needs.
                                    </ShopP>
                                    <ShopSubtitles>
                                        Parametric Games
                                    </ShopSubtitles>
                                </ShopContent>
                                <ShopGrid>
                                    <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                        <div className="d-flex flex-column align-items-start justify-content-between gap-3">
                                            <ShopSubtitles>
                                                Component Chain
                                            </ShopSubtitles>
                                            <ShopP>
                                                Begin your home transformation with pre-built components and top-quality materials, sourced
                                                directly from trusted suppliers in the component chain.
                                            </ShopP>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <ShopA href="https://shop.homeqube.com/buyer/s?sellerType=Product" target="_blank" rel="noopener noreferrer">
                                                <button className="shop-btn btn-lg rounded-pill">
                                                    Shop Now
                                                </button>
                                            </ShopA>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-between h-100">
                                        <div className="d-flex flex-column align-items-start justify-content-between gap-3">
                                            <ShopSubtitles>
                                                Asset Optimization
                                            </ShopSubtitles>
                                            <ShopP >
                                                Discover incredible short-term rental options from trusted property owners, offering you the
                                                best value and a seamless booking experience.
                                            </ShopP>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <ShopA href="https://shop.homeqube.com/buyer/s?sellerType=Product" target="_blank" rel="noopener noreferrer">
                                                <button className="shop-btn btn-lg rounded-pill">
                                                    Book Now
                                                </button>
                                            </ShopA>
                                        </div>
                                    </div>
                                </ShopGrid>
                            </ShopFlexSideB>
                        </ShopFlexContainer>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ShopSection;
