import React from 'react'
import styled from 'styled-components'
import worldmap from "../../images/Home/world 1.jpg"

const CommunityGrid = styled.div`
    width: 100%;
    height: 100%;
    gap: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1170px) {
      flex-direction: column-reverse;
    }
`;

const CommunityFlexSideA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100%;
  width: 60%;

  @media (max-width: 1480px) {
    padding: 3rem;
  }

  @media (max-width: 1170px) {
    width: 100%;
    padding: 2rem;
  }

  @media (max-width: 720px) {
    padding: 1rem;
  }
`;

const CommunityFlexSideB = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 2rem;
  height: 100%;
  width: 40%;

  @media (max-width: 1480px) {
    padding: 3rem;
  }

  @media (max-width: 1170px) {
    width: 100%;
    padding: 2rem;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 720px) {
    padding: 1rem;
  }
`;

const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
`;

const CommunityTitle = styled.h2`
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: right;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;
    

    @media (max-width: 1280px) {
          font-size: 3rem;
    }

    @media (max-width: 1170px) {
        text-align: center;
        font-size: 2.8rem;
      }

    @media (max-width: 720px) {
          font-size: 2.5rem;
    }
`;

const CommuntiySubTitle = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    /* identical to box height, or 70px */
    text-align: right;
    color: #ffffff;

    @media (max-width: 1170px) {
      text-align: center;
      font-size: 1.2rem;
    }
`;

const CommunityButton = styled.a`
    @media (max-width: 580px) {
        width: 100%;
    }
    
`;


function CommunitySection() {
  return (
    <section className="community-Section text-light px-2 py-5 text-center w-100 h-100" id="Community">
      <div className="container-community-Section h-100 w-100">
        <CommunityGrid>
          <CommunityFlexSideA>
            <RoadmapContainer>
              <div class="ai-header">
                <p class="ai-title text-black">
                  Homeqube Roadmap
                </p>
                <br />
                <center>
                  {/* <div class="home-rectangle"></div> */}
                </center>

              </div>
              <img src={worldmap} class="img-fluid worldmap1" alt="" />
            </RoadmapContainer>
          </CommunityFlexSideA>
          <CommunityFlexSideB>
            <CommunityTitle>
              HomeQube Community
            </CommunityTitle>
            <CommuntiySubTitle>
              Explore HomeQube’s Community hub   for FAQs, updates, articles, and discussions.
            </CommuntiySubTitle>
            <div className="d-flex flex-row align-items-end mt-5">
              <CommunityButton href="https://www.community.homeqube.com/en" target="_blank" rel="noopener noreferrer">
                <button className="shop-btn btn-lg rounded-pill">
                  Join the Conversation
                </button>
              </CommunityButton>
            </div>
          </CommunityFlexSideB>
        </CommunityGrid>
      </div>
    </section>
  )
}

export default CommunitySection