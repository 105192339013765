import React from "react";


//partners
import proptechforgoodlogo2 from "../../images/Home/press/proptechforgoodlogo2.png"
import proptechinstiturewhitelogo from "../../images/Home/press/proptechinstiture-white_logo.jpg"
import proptechlogo from "../../images/Home/press/proptechlogo.png"
import uli from "../../images/Home/press/uli-logo.png"
import basingapore from "../../images/Home/press/ba-singapore.png"
import sfalogo from "../../images/Home/press/sfalogo-white.png"
import dwic from "../../images/Home/press/dwic.png"
import usproptechcouncil from "../../images/Home/press/us_proptech_council_logo.png"

function IndustryPartners() {
    return (
        <section className="companies text-light p-5 text-center" >
            <div className="companies-content-container container">
                <div className="d-flex flex-column justify-content-center align-items-center companies-content-flex">
                    <div className="companies-title-container pb-5 pt-5">
                        <h5 class="companies-title">
                            Our Industry Partners in Building Your Future
                        </h5>
                    </div>
                    <div className="companies-partners-container">
                        <div className="companies-partners-flex">
                            <div className="companies-partners">
                                <img src={uli} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={proptechlogo} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={proptechforgoodlogo2} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={proptechinstiturewhitelogo} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={sfalogo} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={basingapore} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={dwic} alt="" />
                            </div>
                            <div className="companies-partners">
                                <img src={usproptechcouncil} alt="" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default IndustryPartners