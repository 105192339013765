import React from 'react'
import styled from 'styled-components';

import dappCheckIcon from '../../images/CorporateFacelift/icons/dapp-check-icon.png'
import dappImg from '../../images/CorporateFacelift/img/dapp-image.png'

const DappGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const DappFlexSideA = styled.div`
  padding-top: 3rem !important;
  padding-bottom: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  background-color: #b8b9bb;
  padding: 1rem;
  gap: 3rem;

  @media (max-width: 1170px) {
    width: 100%;
  }
`;

const DappFlexSideB = styled.div`
  padding-top: 3rem !important;
  padding-bottom: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  background-color: #28282d;
  padding: 1rem;
  gap: 3rem;
  height: 100%;

  @media (max-width: 1170px) {
    width: 100%;
  }


  @media (max-width: 1280px) {
        display: none;
    }
`;
const DappTitleA = styled.h2`
    display: block;
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: right;
    letter-spacing: 2px;
    color: #28282d;
    text-transform: uppercase;
    width: 100%;

    @media (max-width: 1280px) {
          font-size: 3rem;
          display: none;
    }

    @media (max-width: 1170px) {
        text-align: center;
        font-size: 2.8rem;
      }

    @media (max-width: 720px) {
          font-size: 2.5rem;
    }
`;

const DappTitleMobile = styled.h2`
    display: none;
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 1x;
    color: #28282d;
    width: 100%;

    @media (max-width: 1280px) {
        display: block;
        font-size: 4rem;
    }

    @media (max-width: 1170px) {
        text-align: center;
        font-size: 3.2rem;
      }

    @media (max-width: 720px) {
          font-size: 3rem;
    }
`;

const DappTitleB = styled.h2`
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: left;
    letter-spacing: 2px;
    color: #fff;
    width: 100%;

    @media (max-width: 1280px) {
          font-size: 3rem;
    }

    @media (max-width: 1170px) {
        text-align: center;
        font-size: 2.8rem;
      }

    @media (max-width: 720px) {
          font-size: 2.5rem;
    }
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: left;
    color: #000000;
    width: 100%;

    @media (max-width: 720px) {
          font-size: 2rem;
    }
`;

const P = styled.p`
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: left;
    color: #000000;
    line-height: 150%;

    @media (max-width: 720px) {
          font-size: 1.2rem;
    }
    
`;

const DappCheckIcon = styled.img`
    width: 25%;
    border-radius: 10px;
    display: block;

    @media (max-width: 720px) {
        display: none;
    }
`;

const DappImage = styled.img`
    position: relative;
    width: 70%;
    height: 90% !important;

    @media (max-width: 1280px) {
      width: 100%;
      height: 700px !important;
    }
`;

const InfoFlex = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
  justify-content: space-between; 

    @media (max-width: 1280px) {
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
        justify-content: center; 
    }

    @media (max-width: 720px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const A = styled.a`
    @media (max-width: 580px) {
        width: 100%;
    }
`;

const Info = styled.div`
    width: 75%;
    @media (max-width: 720px) {
        width: 100%;
    }
`;

const Content = styled.div`
    gap: 3rem;
    @media (max-width: 920px) {
      gap: 1rem;
    }
`;

function DappSection() {
  return (
    <section className="dapp-Section text-light text-center w-100 h-100 p-0 m-0" id="DApp">
      <div className="container-dapp-Section h-100 w-100">
        <DappGrid className="h-100">
          <DappFlexSideA>
            <DappTitleA>
              HomeQube
            </DappTitleA>
            <DappTitleMobile>
              HomeQube DApp
            </DappTitleMobile>
            <Content className="d-flex flex-column align-items-center justify-content-center w-100">
              <InfoFlex className="d-flex flex-column align-items-start justify-content-center w-100">
                <Title>
                  Decentralized Application
                </Title>
                <P className='w-75'>
                  Our DApp is an intuitive application provides Home Parts based on any image you send
                  providing Generative Engineering and Generative Supply Chain. (Still in Development)
                </P>
              </InfoFlex>
              <InfoFlex className="d-flex flex-row align-items-center justify-content-center w-100 gap-4">
                <DappCheckIcon src={dappCheckIcon} alt="Check" />
                <Info className="d-flex flex-column align-items-start justify-content-center">
                  <P><b>Lot Visualization:</b> Accurate representation of your property.</P>
                  <P><b>Centroid Location:</b> Optimal placement for maximum efficiency.</P>
                  <P><b>House Massing:</b> Visualize the shape, size, and placement of your home.</P>
                </Info>
              </InfoFlex>
              <InfoFlex className="d-flex flex-row w-100 gap-4">
                <A href="https://dapp.homeqube.com/login" target="_blank" rel="noopener noreferrer">
                  <button className="shop-btn btn-lg rounded-pill">
                    Create a Project
                  </button>
                </A>
              </InfoFlex>
            </Content>
          </DappFlexSideA>
          <DappFlexSideB>
            <DappTitleB>
              DApp
            </DappTitleB>
            <div className="d-flex flex-column align-items-center justify-content-start w-100 h-100">
              <DappImage src={dappImg} alt="Image" />
            </div>
          </DappFlexSideB>
        </DappGrid>
      </div>
    </section>
  )
}

export default DappSection