import React from "react";
import BuiltEnvironmentEngineItem from "../../components/BuiltEnvironmentEngineItem";

import AiPoweredDesignOptimizationImg from "../../images/CorporateFacelift/img/AI-Powered-Design-Optimization-Image.png"
import SUstainabilityAnalyticsImg from "../../images/CorporateFacelift/img/Sustainability-Analytics-Image.png"
import AutomatedResourceAllocationImg from "../../images/CorporateFacelift/img/Automated-Resource-Allocation-Image.png"
import BlockChainEnabledTransparencyImg from "../../images/CorporateFacelift/img/Blockchain-Enabled-Transparency-Image.png"

function BuiltEnvironmentEngineSection() {
    return (
        <>
            <section className="built-environment-engine-section text-light py-5 text-center w-100" >
                <div className="h-100 w-100 py-5">
                    <div className="bee-flex justify-content-center align-items-center">
                        <div className="bee-container">
                            <h2 className="built-environment-engine-title mb-3">
                                Built <br />Environment <br />Engine
                            </h2>
                            <h3 className="built-environment-engine-subtitle mb-4">for the Home Value Chain:<br />
                                <b>Home Component Supply Networks</b> <br />(Products, 3D Parts, Assets) and <br /> NFTs for DAO Home Finance
                            </h3>
                            <div className="built-environment-engine-container mb-3">
                                <p className="built-environment-engine-p">
                                    The Build Environment Engine (BEE) is HomeQube’s innovative solution designed to address the complexities and
                                    inefficiencies in the traditional homebuilding process. BEE integrates advanced technologies like artificial
                                    intelligence, blockchain, and systems engineering to streamline the entire lifecycle of home construction, from
                                    conceptual design to final build.
                                </p>
                                <div className="built-environment-engine-p-line"></div>
                            </div>
                        </div>
                        <div className="bee-container">
                            <div className="built-environment-engine-grid">
                                <BuiltEnvironmentEngineItem
                                    image={AiPoweredDesignOptimizationImg}
                                    title={`AI-Powered Design Optimization`}
                                    description={`BEE uses artificial intelligence to analyze vast amounts of data and generate optimal designs tailored to
                                         specific environmental conditions and user requirements. This ensures that every home built through HomeQube is not
                                          only aesthetically pleasing but also structurally sound and sustainable.`}
                                />
                                <BuiltEnvironmentEngineItem
                                    image={SUstainabilityAnalyticsImg}
                                    title={`Sustainability Analytics`}
                                    description={`Environmental impact is a key concern in modern construction. BEE assesses and integrates sustainable practices
                                         by choosing materials and construction methods that reduce carbon footprint. It also optimizes energy efficiency, helping
                                          create homes that are not only eco-friendly but also cost-effective in the long run. `}
                                />
                                <BuiltEnvironmentEngineItem
                                    image={AutomatedResourceAllocationImg}
                                    title={`Automated Resource Allocation`}
                                    description={`BEE intelligently manages and allocates resources based on project requirements, minimizing waste and reducing
                                         costs. This helps in efficient use of materials and labor, ensuring that the building process is both time and 
                                         cost-efficient.`}
                                />
                                <BuiltEnvironmentEngineItem
                                    image={BlockChainEnabledTransparencyImg}
                                    title={`Blockchain-Enabled Transparency`}
                                    description={`Leveraging blockchain technology, BEE provides transparent and immutable records of the entire building process. 
                                        This ensures accountability, traceability, and trust for all stakeholders involved, from architects and contractors to 
                                        homeowners and regulators. `}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BuiltEnvironmentEngineSection;
