import React from "react";
import ServicesCard from "../../components/ServicesCard";
import supplytrapicon from "../../images/CorporateFacelift/icons/supply-trap.png"
import materialtrapicon from "../../images/CorporateFacelift/icons/material-trap.png"
import knowledgetrapicon from "../../images/CorporateFacelift/icons/knowledge-trap.png"
import capitaltrapicon from "../../images/CorporateFacelift/icons/capital-trap.png"
import islandtrapicon from "../../images/CorporateFacelift/icons/island-trap.png"

function TrapsSection() {
    return (
        <>
            <section className="our-services text-light px-2 py-5 text-center w-100" >
                <div className="container-bg-our-services h-100 w-100">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="our-services-title-container">
                            <h2 class="our-services-title">
                                Breaking Free From <b >TRAPS</b>
                            </h2>
                            <p className="traps-subtitle">HomeQube addresses the systemic challenges, or "TRAPS," that have long hindered the housing industry.</p>
                        </div>
                        <div className="our-services-content-container d-flex align-items-start justify-content-center w-100">
                            <ServicesCard 
                                title={"Supply Trap"}
                                content={"Market inefficiencies lead to unsold inventories, driving up home construction costs. HomeQube’s Home Value Chain Bidding Shop, using $QUBE Tokens and Parametric Games, ensures that both short-term stays and building materials are efficiently utilized, stabilizing the market."}
                                image={supplytrapicon}
                                btnname={"Join The Bidding"}
                                btnlink={"https://shop.homeqube.com/"}
                            />
                            <ServicesCard 
                                title={"Material Trap"}
                                content={"Traditional materials like cement and steel make homes immovable, inflating land prices and reducing flexibility. HomeQube’s innovative AI PEB Analog Products, made from lightweight and sustainable materials like GFRP, enable relocatable, adaptable, and eco-friendly housing solutions."}
                                image={materialtrapicon}
                                btnname={"Explore AI PEB Homes"}
                                btnlink={"https://www.peb.homeqube.com/"}
                            />
                            <ServicesCard 
                                title={"Knowledge Trap"}
                                content={"Fragmented building sciences cause delays, inefficiencies, and high costs in homebuilding. HomeQube's systems engineering and generative AI streamline the entire process, from design to production, ensuring efficient and bespoke homebuilding."}
                                image={knowledgetrapicon}
                                btnname={"Start Your AI-Powered Build"}
                                btnlink={"https://dapp.homeqube.com/login"}
                            />
                            <ServicesCard 
                                title={"Capital Trap"}
                                content={`Financial barriers limit homeownership for underserved communities. HomeQube’s Regenerative Finance (ReFi) model, inspired by the Filipino behavior called "Paluwagan," pools community funds to increase purchasing power and make homeownership more accessible.`}
                                image={capitaltrapicon}
                                btnname={"Empower Homeownership"}
                                btnlink={"https://dao.homeqube.com/"}
                            />
                            <ServicesCard 
                                title={"Island Trap"}
                                content={`Building on islands is challenging due to limited resources and high costs. HomeQube’s subsidiary, C Ideation, provides marine mobility solutions like the 14-meter Carbon Fiber Kevlar Hull landing craft, making it easier to transport construction materials and improve island livability.`}
                                image={islandtrapicon}
                                btnname={"Discover C Ideation"}
                                btnlink={"https://www.cideation.com/"}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TrapsSection;
