import React from 'react'
import styled from 'styled-components';

import daoIcon from "../../images/CorporateFacelift/icons/dao-title-icon.png"
import DaoCard from '../../components/DaoCard';

const DaoTitle = styled.h2`
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 4rem;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;

@media (max-width: 1280px) {
        font-size: 3rem;
}

@media (max-width: 720px) {
        font-size: 2.5rem;
}
`;

const DaoSubTitle = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    /* identical to box height, or 70px */
    text-align: center;
    color: #ffffff;
`;


const DaoTitleIcon = styled.img`
    width: 120px;
    height: 120px;

    @media (max-width: 920px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 720px) {
        width: 80px;
        height: 80px;
    }

    @media (max-width: 580px) {
        width: 100px;
        height: 100px;
    }
`;

const DaoFlex = styled.div`
    flex-wrap: wrap;
`;

const TitleFlex = styled.div`
    flex-direction: row;

    @media (max-width: 580px) {
        flex-direction: column;
    }
`;

function DaoSection() {
    return (
        <section className="dao-Section text-light px-2 py-5 text-center w-100 h-100" id="DAO">
            <video className="background-video"
                autoPlay
                loop
                muted
                playsInline
                src="/dao-bg-video.mp4">
            </video>
            <div className="container-dao-Section h-100 w-100 d-flex flex-column align-items-center justify-content-center gap-3">
                <TitleFlex className="d-flex align-items-center justify-content-center mb-3 gap-5">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <DaoTitle>
                            HomeQube DAO
                        </DaoTitle>
                        <DaoSubTitle>
                            Decentralized Autonomous Organization
                        </DaoSubTitle>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                        <DaoTitleIcon src={daoIcon} alt="daoIcon" />
                    </div>
                </TitleFlex>
                <DaoFlex className='d-flex align-items-center justify-content-center gap-1'>
                    {/* <DaoCard
                        title={`Decentralized Finance`}
                        title2={`(DeFi)`}
                        content={"Accessible to both accredited and retail investors based on the specific Project Investment Funds, involving Real Estate Hospitality Projects and Room Rights Contracts."}
                        link={"https://dao.homeqube.com/"}
                        btnname={'Explore Further'} 
                    />
                    <DaoCard
                        title={`Welfare Finance`}
                        title2={`(WeFi)`}
                        content={"Provides derivative membership benefits in cases of income loss or unemployment, based on contributions."}
                        link={"https://dao.homeqube.com/"}
                        btnname={'Read More'} 
                    /> */}
                    <DaoCard
                        title={`Regenerative Finance`}
                        title2={`(ReFi)`}
                        content={`Inspired by the Filipino "Paluwagan" community fund-pooling system, it empowers underserved communities by increasing their purchasing power and supporting pathways to homeownership and home occupancy.`}
                        link={"https://dao.homeqube.com/"}
                        btnname={'View Details'} 
                    />
                </DaoFlex>
            </div>
        </section>
    )
}

export default DaoSection